import { useCallback, lazy, Suspense, FC } from 'react';
import { Route, Routes, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { bootstrapSdk } from '@aiware/js/sdk';
import * as Sentry from '@sentry/react';

const baseElement = document.querySelector('base');

// Initialize to an empty string if baseElement doesn't exist
let baseName = '';

// If a <base> element exists, fetch the href attribute (the base URL)
if (baseElement) {
  baseName = baseElement.getAttribute('href');
  baseName = baseName?.replace?.(window.location.origin, '');
} else {
  baseName = '/';
}

interface IProps {
  baseUrl: string;
}

const RedirectWithQuery = ({ to }: { to: string }) => {
  const location = useLocation();
  const searchParams = location.search; // Get query params
  return <Navigate to={`${to}${searchParams}`} />;
};

const Router: FC<IProps> = ({ baseUrl }) => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  const aiwareInit = useCallback(async () => {
    const { init } = await bootstrapSdk();

    let deferred: (value: unknown) => void = () => {
      //
    };
    const promise = new Promise(resolve => {
      deferred = resolve;
    });
    init(
      {
        applicationId: 'e4739d44-53d2-4153-b55f-5e246fc989b1',
        baseUrl,
        logoutRedirectUrl: '/ui/auth/login',
      },
      () => {
        deferred(true);
      }
    );
    return promise;
  }, [baseUrl]);

  const Desktop = lazy(() => import('./app'));
  const AuthRoutes = lazy(() =>
    import('@aiware/os/auth').then(({ AuthRoutes }) => ({ default: AuthRoutes }))
  );
  const IFramedApplication = lazy(() =>
    import('../components/wallpaper-management/iframe').then(({ IFramedApplication }) => ({
      default: IFramedApplication,
    }))
  );

  return (
    <BrowserRouter basename={baseName}>
      <SentryRoutes>
        <Route
          path="/"
          element={
            <Suspense>
              <Desktop baseUrl={baseUrl} />
            </Suspense>
          }
        />
        <Route
          path="/ui/auth/*"
          element={
            <Suspense>
              <AuthRoutes aiwareInit={aiwareInit} />
            </Suspense>
          }
        />
        <Route path="/logout" element={<RedirectWithQuery to="/ui/auth/logout" />} />
        <Route
          path="/ui/app/:appSlug"
          element={
            <Suspense>
              <IFramedApplication />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </SentryRoutes>
    </BrowserRouter>
  );
};

export default Router;

// if "login" is detected in subdomain, redirect to "/ui/auth/login" with subdomain removed
export function redirectIfLoginSubdomain() {
  const host = window.location.host.split('.');
  const subdomain = window.location.host.split('.')[0];

  if (subdomain === 'login') {
    const rewrittenHost =
      window.location.protocol +
      '//' +
      [...host.slice(1)].join('.') +
      '/ui/auth/login' +
      window.location.pathname +
      window.location.search;
    window.location.replace(rewrittenHost);
  }
}
